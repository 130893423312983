














































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import VsTopMultiActions from './../components/VsTopMultiActions/Index.vue'
import VsCampaignCard from './../components/VsCampaignCard/Index.vue'
import { CampaignsModule } from '@/store/modules/campaigns'
import VsCampaignCreateModal from '@/modules/campaigns/components/CreateCampaignModal/Index.vue'
import VsAssignCampaignLabelTagModal from './../components/VsAssignCampaignLabelTagModal/Index.vue'
import VsPreviewModal from './../components/VsPreviewModal/Index.vue'
import VsSaveModelModal from './../components/VsSaveModelModal/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsEmptyState from '@/components/VsEmptyState/Index.vue'
import VsBlockedFunctionalityModal from '@/components/VsBlockedFunctionalityModal/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsFilterContainer from '@/components/VsFilterContainer/Index.vue'
import {
    getEntityLabels, removeEntityLabelToEntity,
} from '@/api/consoleApi/entityLabels'
import {
    getCampaign,
    getCampaigns,
    deleteCampaignsBulk,
    deleteCampaign,
    duplicateCampaign,
} from '@/api/consoleApi/campaigns'
import { get, union } from 'lodash'
import { UserModule } from '@/store/modules/user'
import CampaignNameModal from '@/modules/campaigns/components/CampaignNameModal/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { AppModule } from '@/store/modules/app'
import { ICampaignFromGetAll } from '@/api/consoleApi/dto/campaigns.dto'
import { getEmailPreview, uploadThumbnailImage } from '@/api/consoleApi/contents/emails'
import { getSmsPreview } from '@/api/consoleApi/contents/sms'
import { downloadHtml, downloadText } from '@/utils/export'
import { getUserCredits } from '@/api/consoleApi/user'
import html2canvas from 'html2canvas'
import { processImage } from '@/utils/screenshot'

@Component({
    name: 'CampaignIndex',
    components: {
        VsTopMultiActions,
        VsCampaignCard,
        VsCampaignCreateModal,
        VsAssignCampaignLabelTagModal,
        VsPreviewModal,
        VsSaveModelModal,
        VsConfirm,
        VsEmptyState,
        VsBlockedFunctionalityModal,
        VsLoader,
        VsContainer,
        CampaignNameModal,
        VsFilterContainer,
    },
})
export default class extends Vue {
    private loading = false
    private campaigns: any[] = []
    private labelTags: any[] = []
    private total = 0
    private showNewCampaignModal = false
    private isClean = true
    private thresholdUnsubscription = ''
    private thresholsSpam = ''
    private thresholdBounce = ''
    private emptyState = true
    private firstStartLoading = true
    private filters = {
        search: '',
        labelTags: [],
        type: null,
        status: [],
    }

    $refs!: {
        deleteCampaignConfirm: VsConfirm
        blockedFunctionality: VsBlockedFunctionalityModal
        campaignNameModal: CampaignNameModal
        previewModal: VsPreviewModal
        saveModelModal: VsSaveModelModal
    }

    private pagination: any = {
        page: 1,
        itemsPerPage: 10,
        orderBy: null,
    }

    get checkedCampaigns () {
        return CampaignsModule.checkedCampaigns
    }

    set checkedCampaigns (val: string[]) {
        CampaignsModule.SET_CHECKED_CAMPAIGNS(val)
    }

    get user () {
        return UserModule.user
    }

    get hasShop () {
        return this.user.configuration.rules.shop
    }

    get userEmailCreditsAvailable () {
        return UserModule.userCredits.find(el => el.key === 'email')?.value || 0
    }

    get rechargeStrategyObjectValue () {
        return this.user.configuration.rules.emailRecursivity?.value || 0
    }

    get showCreditBanner () {
        if (!this.user.configuration.rules.emailRecursivity || this.user.configuration.rules.emailUnlimited) return false
        if (this.userEmailCreditsAvailable <= 0) return true
        if ((this.userEmailCreditsAvailable * 100) / this.rechargeStrategyObjectValue <= 10) return true
        return false
    }

    get canArchiveNewsletter () {
        return this.user.configuration.rules.archive
    }

    get canCampaignComaprison () {
        return this.user.configuration.rules.campaignComparison
    }

    get countFilters () {
        let count = 0
        if (this.filters.labelTags && this.filters.labelTags.length > 0) count++
        if (this.filters.type) count++
        if (this.filters.status && this.filters.status.length > 0) count++
        if (this.filters.search && this.filters.search.length > 0) count++
        return count
    }

    get campaignStatuses () {
        return [
            {
                label: this.$t('campaigns.index.status.Sent'),
                value: 'Sent',
            },
            {
                label: this.$t('campaigns.index.status.Ready'),
                value: 'Ready',
            },
            {
                label: this.$t('campaigns.index.status.Draft'),
                value: 'Draft',
            },
            {
                label: this.$t('campaigns.index.status.Sending'),
                value: 'Sending',
            },
            {
                label: this.$t('campaigns.index.status.Paused'),
                value: 'Paused',
            },
            {
                label: this.$t('campaigns.index.status.Pending Approval'),
                value: 'Pending Approval',
            },
            {
                label: this.$t('campaigns.index.status.Failed'),
                value: 'Failed',
            },
        ]
    }

    get campaignMessageTypes () {
        return [
            {
                label: this.$t('campaigns.index.type.email'),
                value: 'email',
            },
            {
                label: this.$t('campaigns.index.type.sms'),
                value: 'sms',
            },
            {
                label: 'A/B test',
                value: 'splittest',
            },
        ]
    }

    get campaignMessageOrderBy () {
        return [
            {
                label: this.$t('campaigns.index.orderBy.createdAtDesc'),
                value: 'id|desc',
            },
            {
                label: this.$t('campaigns.index.orderBy.createdAtAsc'),
                value: 'id|asc',
            },
            {
                label: this.$t('campaigns.index.orderBy.sentAtDesc'),
                value: 'sent_start|desc',
            },
            {
                label: this.$t('campaigns.index.orderBy.sentAtAsc'),
                value: 'sent_start|asc',
            },
        ]
    }

    get pageAbortController () {
        return AppModule.pageAbortController
    }

    async mounted () {
        await this.getUserCredits()
        await this.setDefaultsFromSessionStorage()
        await this.checkEmptyState()
        await this.getLabelTags()
        this.firstStartLoading = false
    }

    @Watch('filters', { deep: true, immediate: false })
    private saveFiltersOnSessionStorage (val: any) {
        window.sessionStorage.setItem(this.user._id + '-campaigns-filters', JSON.stringify(val))
    }

    @Watch('pagination', { deep: true, immediate: false })
    private savePaginationOnSessionStorage (val: any) {
        window.sessionStorage.setItem(this.user._id + '-campaigns-pagination', JSON.stringify(val))
    }

    @Watch('filters', { deep: true, immediate: false })
    @Watch('pagination', { deep: true, immediate: false })
    async getCampaigns () {
        this.loading = true
        try {
            const resp = await getCampaigns(this.buildParams())
            this.campaigns = resp.data.data
            this.total = resp.data.meta.pagination.total
            if (this.total > 0) this.emptyState = false
            if (resp.data.meta.pagination.current_page > resp.data.meta.pagination.total_pages) {
                this.pagination.page = 1
            }
        } catch (e) {
            this.campaigns = []
            this.total = 0
            console.log(e)
        }
        this.loading = false
    }

    private async getUserCredits () {
        try {
            const resp = await getUserCredits()
            const credits = resp.data
            UserModule.SET_USER_CREDITS(credits)
        } catch (e) {
            console.log(e)
        }
    }

    private async setDefaultsFromSessionStorage () {
        const filters = window.sessionStorage.getItem(this.user._id + '-campaigns-filters')
        const pagination = window.sessionStorage.getItem(this.user._id + '-campaigns-pagination')
        if (filters) this.filters = JSON.parse(filters)
        if (pagination) this.pagination = JSON.parse(pagination)
        await this.getCampaigns()
    }

    private async checkEmptyState () {
        try {
            const resp = await getCampaigns({ limit: 1 })
            if (resp.data.meta.pagination.total > 0) {
                this.emptyState = false
            }
        } catch (e) {
            console.log(e)
            return 0
        }
    }

    private async getLabelTags () {
        try {
            const labelTags: any[] = []
            await this.loopCall(1, labelTags, getEntityLabels)
            this.labelTags = labelTags
        } catch (e) {
            console.log(e)
        }
    }

    async loopCall (page: number, values: any, caller: any) {
        const resp = await caller({
            page,
            limit: 1000,
        })
        values.push(...resp.data.data)
        if (resp?.data?.meta?.pagination?.current_page < resp?.data?.meta?.pagination?.total_pages) {
            await this.loopCall(resp.data.meta.pagination.current_page + 1, values, caller)
        }
    }

    private selectAll () {
        this.checkedCampaigns = union(
            this.checkedCampaigns,
            this.campaigns.map(el => el.id),
        )
    }

    private async deleteCampaigns (ids: any[]) {
        try {
            await this.$refs.deleteCampaignConfirm.openConfirm()
        } catch (e) {
            return
        }
        this.loading = true
        try {
            await deleteCampaignsBulk({ ids })

            this.$root.$vsToast({
                heading: 'Campagne eliminate con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.checkedCampaigns = []
            await this.getCampaigns()
        } catch (e) {
            this.$refs.deleteCampaignConfirm.closeConfirm()
            this.$root.$vsToast({
                heading: 'Errore durante l\'eliminazione delle campagne',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    private async deleteCampaign (id: string) {
        try {
            await this.$refs.deleteCampaignConfirm.openConfirm()
            this.$refs.deleteCampaignConfirm.closeConfirm()
        } catch (e) {
            this.$refs.deleteCampaignConfirm.closeConfirm()
            return
        }
        this.loading = true
        try {
            await deleteCampaign(id)

            this.$root.$vsToast({
                heading: 'Campagna eliminata con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.checkedCampaigns = []
            await this.getCampaigns()
        } catch (e) {
            this.$refs.deleteCampaignConfirm.closeConfirm()
            this.$root.$vsToast({
                heading: 'Errore durante l\'eliminazione della campagna',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    private buildParams () {
        const params: any = {
            orderBy: 'id',
            sortedBy: 'desc',
            page: this.pagination.page,
            limit: this.pagination.itemsPerPage,
            include: 'email,sms,label_tags',
            searchJoin: 'and',
            search: this.buildSearch(),
            searchFields: this.buildSearchFields(),
        }

        if (this.pagination.orderBy) {
            const order = this.pagination.orderBy.split('|')
            params.orderBy = order[0]
            params.sortedBy = order[1]
        }

        return params
    }

    private buildSearch () {
        const search = [
            this.filters.type === 'email' ? 'email.id:0' : '',
            this.filters.type === 'sms' ? 'sms.id:0' : '',
            this.filters.type === 'splittest' ? 'split_test.id:0' : '',
            this.filters.labelTags.length > 0 ? `label_tags.id:${this.filters.labelTags}` : '',
            this.filters.status.length > 0 ? `status:${this.filters.status}` : '',
            this.filters.search.trim() !== '' ? `name:${this.filters.search}` : '',
        ].filter((el) => el !== '')

        return search.join(';')
    }

    private buildSearchFields () {
        const searchFields = [
            this.filters.type === 'email' ? 'email.id:!=' : '',
            this.filters.type === 'sms' ? 'sms.id:!=' : '',
            this.filters.type === 'splittest' ? 'split_test.id:!=' : '',
            this.filters.labelTags.length > 0 ? 'label_tags.id:in' : '',
            this.filters.status.length > 0 ? 'status:in' : '',
            this.filters.search.trim() !== '' ? 'name:like' : '',
        ].filter((el) => el !== '')

        return searchFields.join(';')
    }

    private tagAssigned () {
        this.getCampaigns()
    }

    private tagCreated () {
        this.$root.$vsToast({
            heading: this.$t('campaigns.index.createTagSuccess'),
            timeout: 3000,
            aspect: VsToastAspectEnum.success,
        })
        this.getLabelTags()
    }

    private checkPerformances (checkedCampaigns: any[]) {
        if (!this.canCampaignComaprison) {
            this.$refs.blockedFunctionality.openModal()
        } else {
            window.location.href = `/app/user/campaigns/compare/${checkedCampaigns.join('-')}`
        }
    }

    private async updateCampaign () {
        await this.getCampaigns()
        this.$refs.campaignNameModal.loading = false
        this.$refs.campaignNameModal.closeModal()
    }

    private async cloneCampaign (campaign: any) {
        try {
            const duplicatedCampaign = await duplicateCampaign(campaign.id)
            const newCampaign = await getCampaign(duplicatedCampaign.data)
            if (newCampaign.data.data.content_type === 'email') {
                const emailData = newCampaign.data.data.message.data
                await this.generatePreviewImage(emailData.id, emailData.html_content)
            }
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Campagna duplicata con successo',
                aspect: VsToastAspectEnum.success,
            })
            this.getCampaigns()
        } catch (e) {
            const errorCode = get(e, 'response.data.message', 0)
            let message = ''
            if (errorCode === 'You don\'t have abTest permission') {
                message = 'Non puoi duplicare la campagna perchè non hai la funzionalità A/B split attiva'
            }
            console.log(e)
            this.$root.$vsToast({
                timeout: 3000,
                message,
                heading: 'Errore durante la duplicazione della campagna',
                aspect: VsToastAspectEnum.alert,
            })
        }
    }

    private async generatePreviewImage (emailId: string, htmlContent: string) {
        try {
            const iframe = document.createElement('iframe')
            iframe.style.position = 'absolute'
            iframe.style.left = '-9999px'
            iframe.style.top = '-9999px'
            iframe.style.width = '700px'
            iframe.style.height = '800px'
            document.body.appendChild(iframe)

            await new Promise(resolve => {
                iframe.onload = resolve
                const doc = iframe.contentDocument || iframe.contentWindow?.document
                if (doc) {
                    doc.open()
                    doc.write(htmlContent)
                    doc.close()
                }
            })

            const canvas = await html2canvas(iframe.contentDocument?.body || iframe, {
                windowWidth: 700,
                width: 700,
                height: 800,
                proxy: '/app/userapi/users/screenshot-images-proxy',
            })

            document.body.removeChild(iframe)
            let base64 = canvas.toDataURL('image/jpeg', 0.1)
            base64 = await processImage(base64)

            await uploadThumbnailImage(emailId, {
                base64_image: base64,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante il salvataggio dell\'anteprima',
                timeout: 3000,
                aspect: VsToastAspectEnum.warning,
            })
            console.log(e)
        }
    }

    private openPreviewModal (campaign: ICampaignFromGetAll) {
        this.$refs.previewModal.openModalNew(campaign[campaign.content_type]?.data, campaign.content_type)
    }

    private saveAsModel (campaign: ICampaignFromGetAll) {
        this.$refs.saveModelModal.openModal(campaign[campaign.content_type]?.data.id)
    }

    private async exportHtml (messageId: number, campaignName: string) {
        try {
            const resp = await getEmailPreview(messageId)
            downloadHtml(resp.data, campaignName)
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Errore durante l\'export dell\'html',
                aspect: VsToastAspectEnum.alert,
            })
        }
    }

    private async exportSms (messageId: string, campaignName: string) {
        try {
            const resp = await getSmsPreview(messageId)
            downloadText(resp.data.body, campaignName)
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Errore durante l\'export dell\'html',
                aspect: VsToastAspectEnum.alert,
            })
        }
    }

    async deleteEntityLabel (id: any, campaignId: any) {
        try {
            await removeEntityLabelToEntity(id, 'campaigns', campaignId)
            this.$root.$vsToast({
                heading: 'Tag rimosso con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.getCampaigns()
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la rimozione del tag',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
    }
}
